<script>
export default {
  name: "ModalSubscribe"
}
</script>

<template>
<div class="modal fade" id="modalRegister" tabindex="-1" role="dialog" aria-labelledby="modalRegister" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-body-content">
                    <h2>launch data yet unavailable</h2>
                    <p>pre-register to follow up on all game and release dates</p>
                </div>
                <form>
                    <input type="email" id="mail" name="mail" placeholder="enter your email">
                    <button type="submit" class="footer_btn btn_default">keep in touch</button>
                </form>
            </div>
            <div class="modal-footer">
                <div class="modal_footer_socials">
                    <a target="blank" class="modal_footer_social" href="#"><span>x</span></a>
                    <a target="blank" class="modal_footer_social" href="https://www.facebook.com/OmertaReborn/"><span>facebook</span></a>
                    <a target="blank" class="modal_footer_social" href="#"><span>tiktok</span></a>
                    <a target="blank" class="modal_footer_social" href="https://www.instagram.com/omertareborn/"><span>instagram</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>