<script>
export default {
  name: "ModalPrivacy"
}
</script>

<template>
<div class="modal fade" id="modalPrivacy" tabindex="-1" role="dialog" aria-labelledby="modalPrivacy" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h2>Privacy policy</h2>
                <div class="modal-body-content">
                    <p>we are committed to
                        protecting your privacy. this privacy policy explains how we collect, use, and protect your
                        personal information when you visit our website and provide your email address. <br><br>
                        information we collect <br> when you sign up to receive updates, we collect your email address.
                        this is the only personal information we collect through our website. <br><br> how we use your
                        information <br> we use your email address solely to send you updates and information. you will
                        not receive any unrelated communications from us. <br><br> data sharing and disclosure <br> no
                        third-party sharing. we do not sell, trade, or otherwise transfer your email address to outside
                        parties. your information is kept confidential and used only for the purposes described in this
                        policy. <br><br> data security <br> we implement a variety of security measures to maintain the
                        safety of your personal information. your email address is stored in secure environments and is
                        accessible only to authorized personnel who have special access rights to such systems. <br><br>
                        your rights <br> if you wish to unsubscribe from our updates, you can do so at any time by
                        following the unsubscribe instructions provided in each email we send or by contacting us
                        directly at privacy@omertareborn.com. <br><br> changes to this privacy policy <br> we may update
                        this privacy policy from time to time to reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. we will notify you of any significant changes by
                        posting the new policy on our website and updating the "last updated" date. <br><br> contact us
                        <br> if you have any questions or concerns about this privacy policy, please contact us at
                        <a href="mailto:privacy@omertareborn.com">privacy@omertareborn.com</a>. <br><br> omerta reborn
                        is a concept of alberta inc. <br> alberta inc.
                        - company registration: 2591111 <br> phone: +1 587 817 0379 <br> address: 36118 range road 145
                        rd paintearth county castor t0c 0x0 alberta <br> canada <br><br> last updated: 8 july 2024
                        <button type="button" class="close btn btn_default close_btn" data-dismiss="modal" aria-label="close">
                            close
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>