<template>
  <div>
    <b-container class="reborn_wrapper">
      <div v-if="hasModal" class="backdrop"></div>
      <div class="reborn_row">
        <div class="reborn_column reborn_column_left">
          <LayoutHeader></LayoutHeader>

          <div class="side-content side_content_small map_mobile">
            <div class="map-container">
              <div class="map">
              </div>
            </div>
          </div>

          <router-view></router-view>

          <div class="column_nav">
            <div class="nav_items">
              <router-link to="/" exact active-class="nav_item_active" class="nav_item btn btn_default btn_alt">{{ $t('menu_home') }}</router-link>
              <router-link to="/features" exact active-class="nav_item_active" class="nav_item btn_default btn btn_alt">{{ $t('menu_features') }}</router-link>
              <router-link to="/about" exact active-class="nav_item_active" class="nav_item btn_default btn btn_alt">{{ $t('menu_about') }}</router-link>

            </div>
          </div>
        </div>
        <div class="reborn_column reborn_column_right">
          <div class="side-content map_desktop">
            <div class="map-container">
              <div class="map">

              </div>
            </div>
          </div>
          <div class="right_form">
            <form>
              <input type="email" id="mail" name="mail" placeholder="enter your email">
              <button type="submit" class="footer_btn btn_default">keep in touch</button>
            </form>
          </div>
        </div>
      </div>
      <div class="row_footer">
        <div class="footer_switch language_desktop">
          <div class="footer_switch_dropdown">
            <a class="active" id="currentLang"><span></span>english</a>
            <div class="switch_lang" id="langList">
              <a><span></span>portuguese</a>
              <a><span></span>dutch</a>
              <a><span></span>turkish</a>
            </div>
          </div>
        </div>
        <div class="footer_socials">
          <a class="footer_social" href="#"><span>x</span></a>
          <a class="footer_social" href="https://www.facebook.com/OmertaReborn/"
             target="blank"><span>facebook</span></a>
          <a class="footer_social" href="#"><span>tiktok</span></a>
          <a class="footer_social" href="https://www.instagram.com/omertareborn/" target="blank"><span>instagram</span></a>
        </div>
      </div>
    </b-container>
    <div class="container footer_container">
      <div class="footer_switch language_mobile">
        <div class="footer_switch_dropdown">
          <a class="active" id="currentLang"><span></span>english</a>
          <div class="switch_lang" id="langList">
            <a><span></span>portuguese</a>
            <a href="#"><span></span>dutch</a>
            <a><span></span>turkish</a>
          </div>
        </div>
      </div>
      <a class="btn btn_default copyright_item" data-toggle="modal" data-target="#modalPrivacy">privacy policy</a>
    </div>

    <ModalPrivacy></ModalPrivacy>
    <ModalSubscribe></ModalSubscribe>
  </div>
</template>

<script>
import ModalPrivacy from "@/components/modals/ModalPrivacy.vue";
import ModalSubscribe from "@/components/modals/ModalSubscribe.vue";
import LayoutHeader from "@/components/layout/LayoutHeader.vue";
import {RouterLink, RouterView} from "vue-router";

export default {
  name: 'App',
  data: function () {
    return {
      hasModal: false,
    }
  },
  components: {
    LayoutHeader,
    ModalPrivacy,
    ModalSubscribe,
    RouterView,
    RouterLink
  },
  mounted() {
    // this.$root.$on('bv::modal::show', () => {
    //   this.hasModal = true;
    // });
    // this.$root.$on('bv::modal::hide', () => {
    //   this.hasModal = false;
    // })
  }
}
</script>

<style>

</style>
