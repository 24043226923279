<template>
  <div class="column_content about">
    <p>{{ $t('about') }}</p>
  </div>
</template>
<script>
export default {
  name: "AboutView"
}
</script>
