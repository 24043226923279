<script>
export default {
  name: "LayoutHeader"
}
</script>

<template>
<div class="column_header">
            <div class="header_logo">
              <a href="https://www.omertareborn.com/"><img src="images/logo.svg?v=1" alt="Omerta Reborn Logo"
                                                           class="img-fluid" style="width:148px;"></a>
            </div>
            <div class="header_slogan">
              <a href="#" class="btn btn_default btn_alt" data-toggle="modal" data-target="#modalRegister">coming
                soon</a>
            </div>
          </div>
</template>

<style scoped>

</style>