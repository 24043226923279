import Vue from 'vue'
import App from './App.vue'

// Libs
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import VueRouter from "vue-router";
Vue.use(VueRouter)

// Style
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.less'

// Custom
import router from "@/router";


import en from './langs/en.json'
import pt from './langs/pt.json'
import nl from './langs/nl.json'

const messages = {
  en,
  pt,
  nl
};

const i18n = new VueI18n({
  locale: 'en', // Set default locale
  fallbackLocale: 'en', // Set fallback locale if current locale is missing translations
  messages, // Set locale messages
});


Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
