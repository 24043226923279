import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import FeaturesView from "@/views/FeaturesView.vue";

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HomeView
    },
    {
      path: '/about',
      component: AboutView
    },
    {
      path: '/features',
      component: FeaturesView
    }
  ]
});


export default router;