<template>
  <div class="column_content features">
    <p>{{ $t('features') }}</p>
  </div>
</template>

<style></style>
<script>
export default {
  name: "FeaturesView"
}
</script>